import { ConfigProvider } from 'antd'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom'

import './App.css'
import Prospect from './Pages/prospect'
import Lead from './Pages/lead'
import Partner from './Pages/partner'
import Policy from './Pages/privacy-policy'

const Main = () => {
  return <></>
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: []
  },
  {
    path: '/potential-lead',
    element: <Navigate to='/tech-for-hybrid-meetings' replace />,
    children: []
  },
  {
    path: '/collaboration-hubs',
    element: <Navigate to='/tech-for-hybrid-meetings' replace />,
    children: []
  },
  {
    path: '/tech-for-hybrid-meetings',
    element: <Prospect />,
    children: []
  },
  {
    path: '/general-lead',
    element: <Lead />,
    children: []
  },
  {
    path: '/partner',
    element: <Partner />,
    children: []
  },
  {
    path: '/privacy-policy',
    element: <Policy />,
    children: []
  },
])

const App = () => {
  return (
    <div className='App'>
      <ConfigProvider
        theme={{
          components: {
            Badge: {
              statusSize: 12,
            },
            Button: {
              colorPrimary: '#313131',
            },
            Steps: {
              colorPrimary: '#F28626',
              lineWidth: 2,
              fontSizeSM: 12,
              fontWeightStrong: 700,
              colorFillContent: '#C8C8CD',
              margin: 1,
              paddingSM: 12
            },
            Form: {
              labelFontSize: 12,
              labelHeight: 20,
              labelColonMarginInlineStart: 2,
              labelColonMarginInlineEnd: 2,
              itemMarginBottom: 20,
              labelRequiredMarkColor: '#D1333D',

            },
            Input: {
              paddingBlock: 10,
              paddingInline: 12
            },
            InputNumber: {
              paddingBlock: 10,
              paddingInline: 12
            },
            Select: {
              paddingBlock: 10,
              paddingInline: 12
            },
            Checkbox: {
              borderRadiusSM: 2,
              paddingXS: 8
            },
            Switch: {
              colorPrimary: '#F28626',
              colorPrimaryHover: '#F28626',
            }
          },
          token: {
            colorBorderBg: '#F28626',
            colorBorderSecondary: '#C8C8CD',
            paddingXS: 2,
            fontSizeIcon: 16,
            colorPrimary: '#313131',
            colorText: '#17171A',
            borderRadius: 8,
            controlOutlineWidth: 1,
            fontFamily: 'Open Sans',
            controlHeight: 44,
            fontSize: 16,
            colorBgContainer: '#fff',
            colorError: '#D1333D',
            paddingSM: 24,
            colorBgContainerDisabled: '#F3F5F8',
            colorTextDisabled: '#ABB3BE'
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
      <div data-testid='app' style={{ display: 'none' }}>  Main App </div>
    </div>
  )
}

export default App

import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Divider,
  Checkbox,
  Switch
} from 'antd'

import Privacy from '../../component/privacy-policy'
import Input from '../../component/FloatInput/index'
import Select from '../../component/FloatSelect/index'
import {phoneCode} from '../../services/phoneCode'
import CityModal from '../../component/add-city'

const LeadStep3 = (props) => {
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [countryCloneId, setCountryCloneId] = useState()
  const [countryType, setCountryType] = useState()
  useEffect(/* istanbul ignore next */() => {
    form.setFieldsValue(props.data ? props.data : {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loading = props.loading ?? false

  const onFinish = (v) => {
    props.onNext(v)
  }

  const addNewCity = (country) =>{
    setOpen(true)
    let c = props.country.find((r) => {
      return r.id === form.getFieldValue(country)
    })
    setCountryCloneId(c)
    setCountryType(country)
  }

  const onCitySuccess = async (dataId, data, type) =>{
    type = type.substring(0, type.lastIndexOf("_"));
    await props.handleCitySearch(data.zipCode, type, form.getFieldValue(`${type}_country`))
    form.setFieldValue(`${type}_city`, dataId.data)
  }

  const contractChecked = props?.contractChecked ?? false
  const data = props?.data ?? {}

  return (
    <div className='nn-fm'>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={/* istanbul ignore next */ (e) => {
        }}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout='vertical'
        initialValues={{}}
        size={'default'}
      >
        <div className='nn-fm-subheader'>
          {props.nameHeader} <span style={{ color: 'var(--primary-orange, #F28626)' }}> </span>
        </div>

        <div className='nn-fm-groupheader'>
          {props.valueKey === 'contract_signature' && 'Contract Signature Contact'}
          {props.valueKey === 'billing_contact' && 'Billing Contact'}
          {props.valueKey === 'delivery_contact' && 'Delivery Contact'}
          {props.valueKey === 'communication_contact' && 'Communication Contact'}
        </div>
        <div className='ui-h3'>
          <div style={{ marginTop: '10px', fontWeight: 400 }}>
            <Checkbox onChange={(e) => {
              props.onChangeCheckbox(e.target.checked, props.valueKey)
            }} checked={contractChecked} data-testid='same-information'>Same as Management Company Information</Checkbox>
          </div>
        </div>
        {contractChecked && <>
          <Form.Item
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.nameKey} job title`}
              label={`${props.nameKey} Job Title`}
              disabled={contractChecked}
              value={data.title ?? ''}
            />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.nameKey} name`}
              label={`${props.nameKey} Full Name`}
              disabled={contractChecked}
              value={data.name ?? ''}
            />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.nameKey} email`}
              label={`${props.nameKey} Email`}
              disabled={contractChecked}
              value={data.email ?? ''}
            />
          </Form.Item>

          <div className='phone-box'>
            <Form.Item rules={[{ required: false, message: '' }]}>
              <Select
                  disabled={contractChecked}
                  label='Prefix'
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                  }}
                  style={{height:"46px"}}
                  optionList={phoneCode.map((r) => {
                    return { value: r.dial, name: `${r.dial} ${r.code}` }
                  })}
                  value={data.prefix ?? ''}
              >
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: /^[0-9\-]+$/, message: 'Only numbers are allowed' }
              ]}
            >
              <Input
                required
                placeholderInput={`2015550123`}
                label={`${props.nameKey} Phone Number`}
                placeholder={`${props.nameKey} Phone Number`}
                disabled={contractChecked}
                value={data.phone ?? ''}
                onKeyDown={(e) => {
                  const regex = /^[0-9\-]+$/;
                  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                  if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>

          <div className='nn-fm-groupheader'>
            {props.valueKey === 'contract_signature' && 'Contract Signature Address'}
            {props.valueKey === 'billing_contact' && 'Billing Address'}
            {props.valueKey === 'delivery_contact' && 'Delivery Address'}
            {props.valueKey === 'communication_contact' && 'Communication Address'}
          </div>
          <Form.Item
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.nameKey} address`}
              label={`${props.nameKey} Address`}
              disabled={contractChecked}
              value={data.location}
            />
          </Form.Item>

          {props?.countryCopy?.length > 1 ? (
            <Form.Item
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Select
                required
                label={`${props.nameKey} Country`}
                disabled={contractChecked}
                value={data.address_country ?? ''}
                optionList={props.countryCopy ? props.countryCopy.map(/* istanbul ignore next */(r) => {
                  return { value: r.id, name: `${r.name}` }
                }) : []}
              >
              </Select>
            </Form.Item>
          ) : <div style={{ display: 'none' }}> {props.nameKey} Country No Data</div>}


          <Form.Item
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Select required
              disabled={contractChecked}
              label={`${props.nameKey} City`}
              value={data.address_city ?? ''}
              showSearch
              notFoundContent={null}
              filterOption={/* istanbul ignore next */(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input ?? '').toLowerCase())
              }}
              optionList={props.cityCopy ? props.cityCopy.map(/* istanbul ignore next */(r) => {
                return { value: r.id, name: `${r.name}, ${r.state ? r.state+"," : ''} ${r.zip || ''}` }
              }) : []}
            >
            </Select>
          </Form.Item>
        </>}
        {!contractChecked && <>
          <Form.Item
            name={`${props.valueKey}_title`}
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.namePlaceholderKey} job title`}
              label={`${props.nameKey} Job Title`}
              disabled={contractChecked}
            />
          </Form.Item>

          <Form.Item
            name={`${props.valueKey}_name`}
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.namePlaceholderKey} name`}
              label={`${props.nameKey} Full Name`}
              disabled={contractChecked}
            />
          </Form.Item>

          <Form.Item
            name={`${props.valueKey}_email`}
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Enter a valid email address' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.namePlaceholderKey} email`}
              label={`${props.nameKey} Email`}
              disabled={contractChecked}
            />
          </Form.Item>

          <div className='phone-box'>
            <Form.Item name={`${props.valueKey}_prefix`} rules={[{ required: false, message: '' }]}>
              <Select
                  label='Prefix'
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                  }}
                  style={{height:"46px"}}
                  optionList={phoneCode.map((r) => {
                    return { value: r.dial, name: `${r.dial} ${r.code}` }
                  })}
              >
              </Select>
            </Form.Item>
            <Form.Item
              name={`${props.valueKey}_phone`}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: /^[0-9\-]+$/, message: 'Only numbers are allowed' }
              ]}
            >
              <Input
                required
                placeholderInput={`2015550123`}
                label={`${props.nameKey} Phone Number`}
                placeholder={`${props.nameKey} Phone Number`}
                disabled={contractChecked}
                onKeyDown={(e) => {
                  const regex = /^[0-9\-]+$/;
                  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                  if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>

          <div className='nn-fm-groupheader'>
            {props.valueKey === 'contract_signature' && 'Contract Signature Address'}
            {props.valueKey === 'billing_contact' && 'Billing Address'}
            {props.valueKey === 'delivery_contact' && 'Delivery Address'}
            {props.valueKey === 'communication_contact' && 'Communication Address'}
          </div>
          <Form.Item
            name={`${props.valueKey}_address`}
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Input
              required
              placeholderInput={`Type your ${props.namePlaceholderKey} address`}
              label={`${props.nameKey} Address`}
              disabled={contractChecked}
            />
          </Form.Item>

          {props.country && props.country.length > 1 ? (
            <>
              <Form.Item
                name={`${props.valueKey}_country`}
                rules={[
                  { required: true, message: 'This field is required' }
                ]}
              >
                <Select
                  required
                  label={`${props.nameKey} Country`}
                  disabled={contractChecked}
                  showSearch
                  onChange={/* istanbul ignore next */ (e) => {
                    props.countryChange(e, props.valueKey)
                    form.setFieldValue(`${props.valueKey}_city`, null)
                  }}
                  filterOption={ /* istanbul ignore next */(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                  }}
                  optionList={props.country ? props.country.map( /* istanbul ignore next */(r) => {
                    return { value: r.id, name: `${r.name}` }
                  }) : []}
                >
                </Select>
              </Form.Item>
              <button
                data-testid='address_country_1'
                type='button'
                onClick={ /* istanbul ignore next */ () => {
                  form.setFieldValue(`${props.valueKey}_country`, 'test')
                }}
                style={{ display: 'none' }}
              > Address Country Test</button>
            </>
          ) : <div style={{ display: 'none' }}> {props.nameKey} Country No Data</div>}

          <Form.Item
            name={`${props.valueKey}_city`}
            rules={[
              { required: true, message: 'This field is required' }
            ]}
          >
            <Select
              required
              disabled={contractChecked}
              showSearch
              label={`${props.nameKey} City`}
              placeholderInput='Search at least 3 characters'
              allowClear
              onSearch={/* istanbul ignore next */(e) => (props.handleCitySearch(e, props.valueKey, form.getFieldValue(`${props.valueKey}_country`)))}
              onClear={/* istanbul ignore next */() => props.setCity([], props.valueKey)}
              notFoundContent={props.sData && props.cCount.current >= 3 ? (
                <div style={{padding:"10px"}}>
                  <p>No results found</p>
                  <Button block onClick={()=>addNewCity(`${props.valueKey}_country`)}>Add New City</Button>
                </div>
              ) : null}
              filterOption={/* istanbul ignore next */(input, option) => {
                return ((option?.name ?? '').toLowerCase().includes((input ?? '').toLowerCase()) && input.length >= 3)
              }}
              optionList={props.city ? props.city.map( /* istanbul ignore next */(r) => {
                return { value: r.id, name: `${r.name}, ${r.state ? r.state+"," : ''} ${r.zip || ''}` }
              }) : []}>
            </Select>
          </Form.Item>
          <button
            data-testid='address_city_1'
            type='button'
            onClick={ /* istanbul ignore next */ () => {
              form.setFieldValue(`${props.valueKey}_city`, 'test')
            }}
            style={{ display: 'none' }}
          > Address City Test</button>
        </>}
        {props?.isShowSecond && <>
          <div className='ui-h3'>
            <div style={{ marginTop: '10px', fontWeight: 400 }}>
              <span className=''> Second copy for Management Company </span>
              <Switch
                size='small'
                onChange={(e) => {
                  props.onChangeCheckbox(!e, 'second_contact')
                }}
                checked={!props.contractCheckedSecond}
                data-testid='second-information'
              />
            </div>
          </div>
          {!props?.contractCheckedSecond && <>
            <Form.Item
              name={'second_contact_title'}
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Input required placeholderInput={`Type your job title`} label={'Job Title'} />
            </Form.Item>

            <Form.Item
              name={'second_contact_name'}
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Input required placeholderInput={`Type your name`} label={'Full Name'} />
            </Form.Item>

            <Form.Item
              name={'second_contact_email'}
              rules={[
                { required: true, message: 'This field is required' },
                { type: 'email', message: 'Enter a valid email address' }
              ]}
            >
              <Input required placeholderInput={`Type your email`} label={'Email'} />
            </Form.Item>


            <div className='phone-box'>
              <Form.Item name={`second_contact_prefix`} rules={[{ required: false, message: '' }]}>
                <Select
                    label='Prefix'
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                    }}
                    style={{height:"46px"}}
                    optionList={phoneCode.map((r) => {
                      return { value: r.dial, name: `${r.dial} ${r.code}` }
                    })}
                >
                </Select>
              </Form.Item>
              <Form.Item
                name={`second_contact_phone`}
                rules={[
                  { required: true, message: 'This field is required' },
                  { pattern: /^[0-9\-]+$/, message: 'Only numbers are allowed' }
                ]}
              >
                <Input
                  required
                  placeholderInput={`2015550123`}
                  label={`${props.nameKey} Phone Number`}
                  onKeyDown={(e) => {
                    const regex = /^[0-9\-]+$/;
                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                    if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </div>
            {/* <Form.Item
              name={'second_contact_phone'}
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Input required placeholderInput={`+12015550123`} label={'Phone Number'} />
            </Form.Item> */}

            <Form.Item
              name={'second_contact_address'}
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Input required placeholderInput={`Type your address`} label={'Address'} />
            </Form.Item>
            {props?.country?.length > 1 ? <>
              <Form.Item
                name={'second_contact_country'}
                rules={[
                  { required: true, message: 'This field is required' }
                ]}
              >
                <Select
                  label={'Country'}
                  required
                  showSearch
                  onChange={ /* istanbul ignore next */(e) => {
                    props.countryChange(e, 'second_contact')
                    form.setFieldValue('second_contact_city', null)
                  }}
                  filterOption={ /* istanbul ignore next */(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                  }}
                  optionList={
                    props?.country.map( /* istanbul ignore next */(r) => {
                      return { value: r.id, name: `${r.name}` }
                    }) ?? []
                  }
                >
                </Select>
              </Form.Item>
              <button
                data-testid='address_country_2'
                type='button'
                onClick={ /* istanbul ignore next */ () => {
                  form.setFieldValue('second_contact_country', 'test')
                }}
                style={{ display: 'none' }}
              >Address Country Test</button>
            </> : <div style={{ display: 'none' }}>{props.nameKey} Country No Data</div>}

            <Form.Item
              name={'second_contact_city'}
              rules={[
                { required: true, message: 'This field is required' }
              ]}
            >
              <Select
                required
                label={'City'}
                placeholderInput='Search at least 3 characters'
                showSearch
                allowClear
                onSearch={ /* istanbul ignore next */(e) => (props.handleCitySearch(e, 'second_contact', form.getFieldValue('second_contact_country')))}
                onClear={ /* istanbul ignore next */() => props.setCity([], 'second_contact')}
                notFoundContent={props.sData && props.cCount.current >= 3 ? (
                  <div style={{padding:"10px"}}>
                    <p>No results found</p>
                    <Button block onClick={()=>addNewCity(`second_contact_country`)}>Add New City</Button>
                  </div>
                ) : null}
                filterOption={ /* istanbul ignore next */(input, option) => {
                  return (option?.name ?? '').toLowerCase().includes((input ?? '').toLowerCase())
                }}
                optionList={
                  props?.city2?.map( /* istanbul ignore next */(r) => {
                    return { value: r.id, name: `${r.name}, ${r.state ? r.state+"," : ''} ${r.zip || ''}` }
                  }) ?? []
                }>
              </Select>
            </Form.Item>
            <button
              data-testid='address_city_2'
              type='button'
              onClick={ /* istanbul ignore next */ () => {
                form.setFieldValue('second_contact_city', 'test')
              }}
              style={{ display: 'none' }}
            > Address City Test</button>
          </>}
        </>}

        <Divider />

        {props?.isLast && <>
          <Privacy
            type='prospect'
            onChecked={(e) => {
              props.setBtnDisabled(!e)
            }}
          />
          <Form.Item label=''>
            <div className='nn-space-between-xl'>
              <Button
                loading={false}
                data-testid='back'
                onClick={() => {
                  props.onBack()
                }}
                block
                style={{
                  flex: '1'
                }}
              >BACK</Button>
              <Button
                type='primary'
                data-testid='summit'
                onClick={() => {
                  form.submit()
                }}
                loading={loading}
                block
                disabled={props.btnDisabled}
                style={{
                  flex: '1.5'
                }}
              >SUBMIT</Button>
            </div>
          </Form.Item>
        </>}
        {!props.isLast && <>
          <Form.Item label=''>
            <div className='nn-space-between-xl'>
              <Button
                data-testid='back'
                onClick={() => {
                  props.onBack()
                }}
                block
                loading={loading}
                style={{
                  flex: '1'
                }}
              >BACK</Button>
              <Button
                type='primary'
                data-testid='summit'
                onClick={() => {
                  form.submit()
                }}
                block
                loading={loading}
                style={{
                  flex: '1.5'
                }}
              >NEXT</Button>
            </div>
          </Form.Item>
        </>}
      </Form>
      <CityModal setOpen={setOpen} open={open} countryId={countryCloneId} success={onCitySuccess} countryType={countryType}/>
    </div>
  )
}

export default LeadStep3